import React, { Component } from 'react';
import { Image } from '../../BlockLibrary/Common/Image';
import orderBy from 'lodash/orderBy';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Seo from '../../seo';
import { getPreviewPage } from '../../../state/app';
import { ContentfulBlockImage } from '../../BlockLibrary/ContentfulBlockImage';

class PreviewPgCategory extends Component {
     constructor(props) {
          super(props);

          this.state = {
               articlePerPage: 12,
               recentArticles: 6,
          }
     }

     loadMoreArticle = () => {
          this.setState({ recentArticles: this.state.recentArticles + this.state.articlePerPage })
     }

     showLessArticle = () => {
          this.setState({ recentArticles: 12 })
     }

     // Get all articles of child categories
     getChildArticles = (categories) => {
          let childArticles = [];
          if (typeof categories != 'undefined' && categories != null) {
               categories.forEach((category) => {
                    let articleArr = this.getChildArticles(category.pg__category);
                    childArticles = [...childArticles, ...category.pg__article, ...articleArr];
               })
          } else {
               return [];
          }

          return childArticles;
     }

     render() {
          const { category, articles, isLoading, pgSidebarImage } = this.props;
          let recentArticlesToShow = [];
          const articlesAfterSorting = orderBy(articles, 'sys.createdAt', 'desc');
          recentArticlesToShow = articlesAfterSorting.slice(0, this.state.recentArticles);

          return (
               <Layout>
                    <Seo
                         title={category.fields.seoTitleTag}
                         canonical={category.fields.seoCanonicalUrl}
                         description={category.fields.seoMetaDescription}
                         robots={category.fields.robotsDirective}
                         image={category.fields.socialImage ? category.fields.socialImage : category.fields.featuredImage}
                    />
                    {
                         isLoading && (
                              <div className="page-loading">Loading ...</div>
                         )
                    }
                    <Header specificPhone={category.fields.specificPhone} header={category.fields.header} tabInd={1}></Header>
                    <section className={`pg-category-content`}>
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-8">
                                        <div className="pg-category-articles">
                                             {category.fields.categoryName && <h1>{category.fields.categoryName}</h1>}
                                             <div className="row articles-wrap">
                                                  {
                                                       recentArticlesToShow.length > 0 && (
                                                            recentArticlesToShow && recentArticlesToShow.map(({ fields: article }, i) => {
                                                                 const slugs = `/pet-gazette/${article.category.fields.slug}/${article.slug}`;

                                                                 return (
                                                                      <div className="col-md-6 article-block" key={i}>
                                                                           <div className="article-detail">
                                                                                <div className="image-wrap">
                                                                                     <a href={slugs} tabIndex={-1}>
                                                                                          {article.featuredImage ? (<Image image={article.featuredImage} />) : <img loading="lazy" src="/images/pet-gazette/article-thumbnail-default.png" alt="no-image" />}
                                                                                     </a>
                                                                                </div>
                                                                                <div className="content">
                                                                                     {article.sidebarTitle && (
                                                                                          <a aria-label={article.sidebarTitle} href={slugs}>
                                                                                               <h3>{article.sidebarTitle}</h3>
                                                                                          </a>
                                                                                     )}
                                                                                     {article.shortDescription && <p>{article.shortDescription}</p>}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 )
                                                            })
                                                       )
                                                  }
                                             </div>
                                             {
                                                  (articles && (articles.length) > 12) ?
                                                       (
                                                            <div className="row layout-btn-gazette">
                                                                 <div className="col-md-6 btn-gazette">
                                                                      <div className="btn-load-more">
                                                                           {
                                                                                (this.state.recentArticles >= articles.length) ?
                                                                                     (<button className="button-white" onClick={this.showLessArticle}>Show less articles</button>)
                                                                                     :
                                                                                     (<button className="button-white" onClick={this.loadMoreArticle}>Load more articles</button>)
                                                                           }
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       ) : ''
                                             }
                                        </div>
                                   </div>
                                   <div className="col-lg-3 sidebar-content">
                                        <div className="image-layout">
                                             <ContentfulBlockImage {...pgSidebarImage} />
                                             {/* <a href="/veterinarians"><img loading="lazy" src="/images/hrpg.jpg" alt="Add pet insurance to your voluntary benefit package" /></a> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </section>
                    <Footer footer={category.fields.footer} tabInd={3}></Footer>
               </Layout>
          )
     }
}

PreviewPgCategory.propTypes = {
     category: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          category: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(PreviewPgCategory);
