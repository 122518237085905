import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Seo from '../../seo';
import { getIsPreviewStatus } from '../../../state/app';
import { VET_CATEGORY_NAME } from '../../utilities';
import ContentfulVetsNewsAndResearch from '../../BlockLibrary/Veterinarians/ContentfulVetsNewsAndResearch';
import ContentfulVetsEducation from '../../BlockLibrary/Veterinarians/ContentfulVetsEducation';
import ContentfulVetsLibrary from '../../BlockLibrary/Veterinarians/ContentfulVetsLibrary';
import ContentfulVetsOurTeam from '../../BlockLibrary/Veterinarians/ContentfulVetsOurTeam';

const VetsCategory = ({ category, isLoading, isPreview }) => {
     const seoTitleTag = category.seoTitleTag ? category.seoTitleTag : `${category.categoryName}`;
     const seoMetaDescription = category.seoMetaDescription ? category.seoMetaDescription : category.shortDescription;
     
     const getMainContent = () => {
          switch (category.categoryName) {
               case VET_CATEGORY_NAME.newsAndResearch:
                    return <ContentfulVetsNewsAndResearch data={category} isPreview={isPreview} />;
               case VET_CATEGORY_NAME.library:
                    return <ContentfulVetsLibrary data={category} />;
               case VET_CATEGORY_NAME.education:
                    return <ContentfulVetsEducation data={category} isPreview={isPreview} />;
               case VET_CATEGORY_NAME.ourTeam:
                    return <ContentfulVetsOurTeam data={category} />;
               case VET_CATEGORY_NAME.event:
                    return <>Events</>;
               default:
                    return <ContentfulVetsNewsAndResearch data={category} />;
          }
     };

     return (
          <>
               <Layout>
                    <Seo
                         title={seoTitleTag}
                         canonical={category.seoCanonicalUrl}
                         description={seoMetaDescription}
                         robots={category.robotsDirective}
                         image={category.socialImage ? category.socialImage : category.featuredImage}
                    />
                    {isLoading && <div className="page-loading">Loading ...</div>}
                    <Header specificPhone={category.specificPhone} header={category.header}></Header>
                    <main id="main-content" role="main">
                         {getMainContent()}
                    </main>
                    <Footer footer={category.footer}></Footer>
               </Layout>
          </>
     );
};

VetsCategory.propTypes = {
     isPreview: PropTypes.bool
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

export default connect(mapStateToProps)(VetsCategory);
